<template>
  <AppBar>
    <div class="audit">
      <div class="top_box">
        <div class="left_box">
          <el-button type="text" icon="el-icon-back" @click="$router.go(-1)"></el-button>
          <!-- projectStateList[projectMsg.state - 1] + "阶段" -->
          <el-button type="text">当前阶段:{{ progressName1 }}</el-button>
          <!-- <el-button type="text">当前阶段:{{ progressList }}</el-button> -->

          <el-button type="text" icon="el-icon-s-tools" @click="dialogVisible = true">查看项目基础设置</el-button>
          <el-button type="text" icon="el-icon-link" @click="copyAlink(projectMsgOne.alink)">复制链接</el-button>
          <el-button
            type="text"
            icon="el-icon-tickets"
            @click="
              () => {
                if (design_id == '') return false;
                $router.push({
                  path:
                    '/record/' + projectMsgOne.id + '/' + projectMsgOne.title,
                });
              }
            "
          >查看项目完整记录</el-button>
        </div>
        <div class="rigth_box">
          <el-button
            round
            size="mini"
            icon="el-icon-error"
            v-show="Buttonmagic && !identityError"
            @click="auditDraft(design_id, 1)"
            :disabled="releObj || projectMsgOne.is_pause == 1||rele"
          >审核不通过</el-button>
          <el-button
            v-show="
              check && $store.state.personal.role_id == 4 && !identityError
            "
            round
            size="mini"
            icon="el-icon-s-promotion"
            :disabled="releObj || projectMsgOne.is_pause == 1"
            @click="Tobeauty(design_id)"
          >推送给主美审核</el-button>
          <el-button
            v-show="Buttonmagic && !identityError"
            round
            size="mini"
            icon="el-icon-success"
            :disabled="releObj || projectMsgOne.is_pause == 1||rele"
            @click="ToPM(design_id, 2)"
          >通过并提交给PM</el-button>
        </div>
        <div class="rigth_box">
          <el-button
            class="uploadbtn"
            round
            size="mini"
            icon="el-icon-upload"
            v-if="feedbackFlag && judgeUploadPermission()"
            @click="dialogUpload = true"
            :disabled="dissjg()"
          >上传设计稿</el-button>
          <el-dialog
            width="500px"
            custom-class="uplaod"
            title="稿件发布"
            :visible.sync="dialogUpload"
            :before-close="handleClose"
          >
            <el-form :model="formData" label-width="auto">
              <el-form-item label="上传稿件">
                <el-upload
                  action="//ghf.site.yscase.com/api/v1.index/uploadFile"
                  list-type="picture-card"
                  :headers="token"
                  :before-upload="isType"
                  :on-success="success"
                  :limit="1"
                  :on-progress="progress"
                  ref="my-upload"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <template #file="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="imgsrc(file)" />
                    <span class="el-upload-list__item-actions">
                      <!-- <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>-->
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </template>
                </el-upload>
              </el-form-item>
              <el-form-item label="稿件描述">
                <el-input
                  type="textarea"
                  :rows="3"
                  resize="none"
                  placeholder="请填写稿件描述信息..."
                  v-model="formData.descr"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="info" round size="mini" @click="upload">立即发布</el-button>
              <el-button round size="mini" @click="down_fal">取 消</el-button>
            </div>
          </el-dialog>
        </div>
        <div
          class="rigth_box"
          v-if="
            $store.state.personal.role_id == 2 ||
            $store.state.personal.role_id == 1
          "
        >
          <el-button
            size="mini"
            round
            v-show="isEdit !== -1"
            @click="pmsubmit()"
            :disabled="pmdisbled()"
          >提交</el-button>
          <el-button
            v-if="pause"
            round
            size="mini"
            icon="el-icon-video-pause"
            :disabled="projectMsgOne.is_pause == 1"
            @click="suspend(pause)"
            v-show="isEdit !== -1"
          >暂停项目</el-button>
          <el-button
            v-else-if="!pause"
            round
            size="mini"
            icon="el-icon-video-play"
            @click="suspend(pause)"
            v-show="isEdit !== -1"
          >重启项目</el-button>

          <el-button
            round
            size="mini"
            icon="el-icon-data-analysis"
            @click="goNext"
            :disabled="projectMsgOne.is_pause == 1"
            v-show="isEdit !== -1"
          >
            {{
            projectMsgOne.progress >= 3
            ? "结束项目"
            : `验收并进入${progressName}阶段`
            }}
          </el-button>
        </div>
      </div>
      <div class="main">
        <div class="title_box" v-if="recordList.project">
          <strong class="project_name">
            <span class="yellow">
              {{
              `【${typeAll[recordList.project.type_id - 1]}】`
              }}
            </span>
            {{ recordList.project.title }}
          </strong>
          <ul>
            <li>
              <i class="el-icon-pie-chart"></i>
            </li>
            <li>
              <i class="el-icon-edit-outline"></i>
              第
              <b>{{ recordList2.length }}</b>次修改
            </li>
            <li>
              <i class="el-icon-reading"></i>
              反馈:第
              <b>{{ feedbackTotal }}</b>次反馈
            </li>
          </ul>
        </div>
        <div class="image_box">
          <swiper ref="mySwiper" :options="swiperOptions" class="swiper-container">
            <swiper-slide v-for="(item, idx) of recordList2" :key="item.id">
              <el-button
                size="mini"
                class="edit-image"
                @click="editImage(item)"
                v-if="
                  (item.status == 2 || item.status == 4) &&
                  judgeUploadPermission()
                "
              >编辑</el-button>
              <div
                :class="
                  activeIndex == idx ? 'viewImg viewImg-active' : 'viewImg'
                "
              >
                <el-image
                  fit="cover"
                  :src="'//ghf.site.yscase.com/' + item.pic"
                  :preview-src-list="srcList"
                  ref="elimage"
                ></el-image>
                <div
                  class="mask"
                  @click="
                    viewRecord(
                      item.id,
                      item.cnt,
                      item.cnt_feedback,
                      item.descr,
                      idx
                    )
                  "
                ></div>
                <i
                  class="el-icon-download"
                  @click="viewdown(idx, item.pic_name)"
                  v-if="item.status == 3 || item.status == 5"
                ></i>
                <i class="el-icon-zoom-in" @click="viewImg(idx)"></i>
              </div>
              <div class="imgBtn-box">
                <div class="redCoolor" v-if="item.status == 4 || item.status == 2">未通过</div>
                <p :class="activeIndex == idx ? 'viewImg-active-p' : ''">{{ item.descr }}</p>
                <p :class="activeIndex == idx ? 'viewImg-active-p' : 'two-img'">
                  {{ item.name }}
                  {{
                  item.update_time
                  ? new Date(parseInt(item.update_time) * 1000)
                  .toLocaleString()
                  .replace(/:\d{1,2}$/, " ")
                  : new Date(parseInt(item.create_time) * 1000)
                  .toLocaleString()
                  .replace(/:\d{1,2}$/, " ")
                  }}
                </p>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button">
            <div class="swiper-button-prev iconfont icon-shuangjiantoushang" slot="button-prev"></div>
            <div class="swiper-button-next iconfont icon-shuangjiantoushang" slot="button-next"></div>
          </div>
        </div>
        <div class="info_box">
          <h6 class="yellow content-fk">反馈内容</h6>
          <div class="content_box">
            <div class="comment" v-for="(item, idx) of feedbackList" :key="idx">
              <!-- <div class="head_img">
                
                <el-avatar
                  size="small"
                  :src="
                    item.thumb
                      ? '//ghf.site.yscase.com' + item.thumb
                      : '//cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
                  "
                ></el-avatar>
              </div>-->
              <ul class="information">
                <li>
                  <div
                    :class="
                      idx == 0 ? 'background background-active' : 'background'
                    "
                  >第{{ feedbackList.length - idx }}次反馈</div>
                  <!-- <span>{{
                    item.name +
                      (item.role_id
                        ? "(" + $common.position[item.role_id - 1] + ")"
                        : "")
                  }}</span>-->
                  <span class="imgName">{{ descr }}</span>
                  <span style="margin-left: 20px">
                    {{
                    getTime(item.create_time)
                    }}
                  </span>
                </li>
                <li class="feedbackMsg">
                  <!-- <template>{{ item.msg }}</template> -->
                  <div v-html="item.msg">

                  </div>
                  <div class="wrap"></div>
                  <template>
                    <el-button
                      v-if="item.alink"
                      type="text"
                      class="lianjie"
                      icon="el-icon-paperclip"
                      @click="copyAlink(item.alink)"
                    ></el-button>
                    <template v-if="item.pics">
                      <a
                        v-for="(url, idx) in item.pics.split(',')"
                        :key="idx"
                        :href="'//ghf.site.yscase.com/' + url"
                        :download="'//ghf.site.yscase.com/' + url"
                      >
                        <el-image
                          v-if="
                            !(
                              url.indexOf('.png') != -1 ||
                              url.indexOf('.jpg') != -1
                            )
                          "
                          class="lianjie"
                          style="width: 50px; height: 50px"
                          :src="imgsrc({ name: url, url })"
                        ></el-image>
                        <el-image
                          v-if="
                            url.indexOf('.png') != -1 ||
                            url.indexOf('.jpg') != -1
                          "
                          class="lianjie"
                          style="width: 50px; height: 50px"
                          :src="
                            '//ghf.site.yscase.com/' +
                            imgsrc({ name: url, url })
                          "
                        ></el-image>
                      </a>
                    </template>
                  </template>
                </li>
                <!-- <li>
                  <div v-for="(src, i) of item.images" :key="i">
                    <img :src="src" />
                  </div>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
        <div
          v-if="
            ($store.state.personal.role_id == 2 ||
              $store.state.personal.role_id == 1) &&
            !recordList2.length == 0
          "
          class="post_comment_box"
        >
          <div>
            <Editor v-model="textarea" :min-height="192" />

            <!-- <el-input
              type="textarea"
              resize="none"
              :rows="4"
              placeholder="请输入需要沟通的内容"
              v-model="textarea"
              @keydown.native="pressEnter($event)"
            >
            </el-input>-->
          </div>
          <ul>
            <li>
              <el-button
                type="text"
                icon="el-icon-paperclip"
                @click="dialogFormVisible = true"
                style="position: relative"
              >
                <template v-if="alink">
                  <i class="el-icon-warning uploadTips"></i>
                </template>
              </el-button>
              <el-button
                type="text"
                icon="el-icon-basketball"
                @click="dialogFormVisible1 = true"
                style="position: relative"
              >
                <template v-if="docs">
                  <i class="el-icon-warning uploadTips"></i>
                </template>
              </el-button>
            </li>
            <li>
              <el-button
                round
                size="mini"
                @click="postComment"
                :disabled="getRole_id==1||isEdit==-1"
              >发布反馈</el-button>
              <el-button round plain size="mini" @click="exportWord">一键生成word</el-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog title="上传链接" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="链接地址:" label-width="120">
          <el-input v-model="alink" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="upAlink">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="上传资料文件" :visible.sync="dialogFormVisible1">
      <el-form>
        <el-form-item label-width="120">
          <el-upload
            ref="upload"
            action="//ghf.site.yscase.com/api/v1.index/uploadFile"
            list-type="picture-card"
            :headers="token"
            :before-upload="isType"
            :on-success="successFn"
            :on-error="errorFn"
            :on-remove="removeFn"
          >
            <i class="el-icon-upload"></i>
            <!-- 
            <template #file="{ file }" >
            <img class="el-upload-list__item-thumbnail" :src="imgsrc(file)" /></template>-->
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelUpload">取 消</el-button>
        <el-button type="primary" @click="upfile">确 定</el-button>
      </div>
    </el-dialog>
    <!-- <slot name="dialog"> -->
    <!-- <Dialog
      :state="projectMsg.state"
      :status="projectMsg.status"
      :dialogVisible="dialogVisible || addProject == 1"
      :id="projectId"
      :isRestart="isRestart"
      @dialogVisible="addProjectCheck"
      @restartProject="saveProjectCheck"
    />-->
    <Dialog
      :state="projectMsg.state"
      :status="projectMsg.status"
      :dialogVisible="dialogVisible || addProject == 1"
      :id="projectId"
      :isRestart="isRestart"
      @dialogVisible="addProjectCheck"
    />
    <!-- </slot> -->
  </AppBar>
</template>
<style lang="less" scoped>
@deep: ~">>>";

@{deep}.el-upload {
  margin: 0 auto;
  display: block;
}
@{deep}.el-form-item__content > div {
  display: flex;
}
.uploadTips {
  font-size: 12px;
  color: red;
  position: absolute;
  right: 0;
  top: -2px;
}
.imgName {
  max-width: 300px;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.audit {
  width: 100%;
  height: 100%;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 30px;
    bottom: -20px;
    // background-color: #fff;
  }
}
@{deep}.top_box {
  display: flex;
  justify-content: space-between;
  line-height: 28px;
  .left_box {
    .el-button {
      padding: 0;
      color: #666;
    }
    .el-button:nth-child(n + 3) {
      margin-left: 30px;
    }
  }
  .rigth_box {
    // text-align: right;
    & > .el-button {
      margin-left: 20px;
      &:last-child {
        background-color: #696c70;
        color: #eee;
      }
    }
    .uploadbtn {
      background-color: #696c70;
      color: #eee;
    }
  }
  .uplaod {
    border-radius: 6px;
    .el-dialog__header {
      padding: 10px;
      padding-left: 14px;
      border-bottom: 1px solid #ddd;
      line-height: 1;
      .el-dialog__title {
        font-size: 16px;
      }
      .el-dialog__headerbtn {
        top: -8px;
        right: -60px;
        font-size: 40px;
        .el-dialog__close {
          color: #eee;
        }
      }
    }
    .el-dialog__body {
      padding: 20px 14px 0;
      .el-form {
        .el-form-item {
          margin-bottom: 10px;
        }
      }
      .el-upload-list {
        display: inline-block;
        line-height: 1;
        .el-upload-list__item {
          width: 80px;
          height: 80px;
          img {
            background-color: #ebebeb;
            object-fit: scale-down;
          }
        }
      }
      .el-upload {
        width: 80px;
        height: 80px;
        line-height: 88px;
        background-color: #ebebeb;
      }
    }
    .dialog-footer {
      display: flex;
      justify-content: center;
    }
  }
}

.main {
  // position: absolute;
  // top: 48px;
  // bottom: 0;
  // left: 0;
  // right: 0;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px 0;

  .yellow {
    color: rgb(216, 151, 70);
  }

  .title_box {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .project_name {
      width: 60%;
      line-height: 24px;
      font-size: 16px;
    }
    ul {
      height: 40px;
      border: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      li {
        margin: 0 15px;
        &:first-child {
          margin: 0;
          height: 100%;
          width: 40px;
          line-height: 40px;
          text-align: center;
          background-color: #ebebeb;
          color: #696c70;
          font-size: 16px;
        }
        b {
          color: #e87878;
        }
      }
    }
  }
  .image_box {
    min-height: 151px;
    margin-top: 24px;
    position: relative;
    padding: 0 50px;
    .swiper-container {
      @{deep}.swiper-wrapper {
        align-items: center;
      }
      .swiper-slide {
        width: auto;
        max-width: 220px;
        height: auto;
        min-width: 200px;
        min-height: 170px;
        max-height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        align-items: center;
        /* margin-right: 20px; */
        box-sizing: border-box;
        .imgBtn-box {
          width: 100%;
          display: flex;
          flex-direction: column;
          .redCoolor {
            position: absolute;
            left: 0;
            top: 0;
            // transform: translate(-50%,-50%);
            width: 80px;
            height: 40px;
            font-size: 20px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            background-color: red;
          }
          p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
        .edit-image {
          position: absolute;
          right: 0;
          top: 0;
          color: #fff;
          height: 30px;
          font-size: 12px;
          background-color: #666;
          z-index: 100;
        }
        .viewImg {
          flex: 1;
          width: 100%;
          position: relative;
          .mask {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
          .el-icon-download {
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 16px;
            color: #fff;
            padding: 5px;
            background-color: #696c70;
          }
          .el-icon-zoom-in {
            position: absolute;
            padding: 5px;
            background-color: #696c70;
            color: #fff;
            font-size: 16px;
            right: 0;
            bottom: 0;
            z-index: 10;
          }
          .el-image {
            width: 100%;
            height: 170px;
            vertical-align: middle;
          }
        }
        p {
          margin-top: 10px;
          align-self: flex-start;
          text-align: center;
          font-size: 12px;
          width: 100%;
        }
        .two-img {
          color: #999;
        }
        .viewImg-active {
          .el-image {
            width: 110%;
            height: 190px;
            vertical-align: middle;
          }
          .mask {
            height: 190px;
            box-shadow: 5px 5px 50px 5px rgb(216, 151, 70) inset;
          }
        }
        .viewImg-active-p {
          color: rgb(216, 151, 70);
          font-size: 14px;
        }
        &:hover {
          // width: 220px;
          // height: 200px;

          .viewImg {
          }
          .mask {
            box-shadow: 5px 5px 50px 5px rgb(216, 151, 70) inset;
          }
          p {
            font-size: 14px;
            color: rgb(216, 151, 70);
          }
          .el-image {
            width: 110%;
            height: 190px;
            vertical-align: middle;
          }
          // .viewImg-active {
          //   transform: scale(1.1);
          //   .mask {
          //     box-shadow: 5px 5px 50px 5px rgb(216,151,70) inset;
          //   }
          // }
          .viewImg-active-p {
            color: rgb(216, 151, 70);
            font-size: 14px;
          }
        }
      }
      // .swiper-slide-active {
      //   width: 220px;
      //   height: 170px;
      //   display: flex;
      //   flex-direction: column;
      // }
    }

    .swiper-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 151px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        display: none;
      }
      &::before {
        transform: rotate(90deg);
        background-color: #696c70;
        border-radius: 50%;
        color: #fff;
        padding: 10px;
        font-size: 12px;
      }
      &.swiper-button-prev {
        &::before {
          transform: rotate(-90deg);
        }
      }
    }
  }
  .info_box {
    padding: 0 20px;
    margin-top: 20px;
    h6 {
      font-size: 16px;
    }

    .information li {
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    .wrap {
      width: 100%;
    }
    .feedbackMsg {
      color: #000;
      font-size: 16px;
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
      .lianjie {
        width: 50px;
        height: 50px;
        font-size: 30px;
        margin-right: 20px;
        border: 1px solid #ccc;
        .el-image__error {
          margin-left: 0;
        }
      }
    }
    .background {
      position: relative;
      width: 106px;
      height: 25px;
      background-color: rgb(175, 175, 175);
      margin-right: 16px;
      color: #fff;
      text-align: center;
      line-height: 25px;
      font-size: 16px;
      &:after {
        position: absolute;
        top: 50%;
        margin-top: -7px;
        right: -14px;
        content: "";
        border: 7px solid transparent;
        border-left-color: rgb(175, 175, 175);
        // width: 8px;
        // height: 14px;
        // background-color: rgb(175,175,175);
      }
    }
    .background-active {
      background-color: rgb(216, 151, 70);
      &:after {
        border-left-color: rgb(216, 151, 70);
      }
    }
    .content_box {
      height: 300px;
      margin-top: 15px;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
      }
      // <!--修改 滚动条的 下面 的 样式-->
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      // <!--修改 滑块 -->
      &::-webkit-scrollbar-thumb {
        background-color: #ebebeb;
        border-radius: 10px;
      }
    }
    .comment {
      display: flex;
      margin-bottom: 15px;
      ul {
        margin-left: 15px;
        li {
          &:nth-child(1) {
            line-height: 28px;
            color: #afafaf;
          }
          &:nth-child(2) {
            margin-bottom: 10px;
            width: 98%;
          }
          &:nth-child(3) {
            display: flex;
            div {
              width: 135px;
              margin-right: 14px;
              //   height: 84px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
      .information {
        margin-left: 0;
        width: 90%;
        .feedbackMsg {
          position: relative;
          margin-bottom: 10px;
          &:after {
            position: absolute;
            bottom: -20px;
            right: 53px;
            content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -";
            overflow: hidden;
            width: 95%;
            text-align: left;
            height: 20px;
            font-size: 20px;
            color: rgb(175, 175, 175);
            // border-bottom: 1px dashed #000;
          }
        }
      }
    }
  }
  .post_comment_box {
    margin-top: 20px;
    padding: 0 20px;
    & > div {
      @{deep}.el-textarea {
        textarea {
          padding: 15px 20px;
          border-color: #7f8488;
          border-radius: 6px;
          background-color: #ebebeb;
          font-size: 12px;
          &::-webkit-input-placeholder {
            color: #b3b3b3;
          }
        }
      }
    }
    ul {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      li:first-child {
        .el-button {
          padding: 0;
          font-size: 22px;
        }
      }
      li:last-child {
        .el-button:first-child {
          background-color: #696c70;
          color: #eee;
        }
      }
    }
  }
}
</style>
<script>
// import JSZipUtils from "jszip-utils"
// import docxtemplater from "docxtemplater"
// import JSZip from 'jszip'
// import PizZip from 'pizzip'
// import {saveAs} from 'file-saver'
// import docxtemplater from 'docxtemplater';
// import { saveAs } from 'file-saver';
// import JSZip from 'jszip';
// import JSZipUtils from 'jszip-utils';
// 主美
var leadid = null;
// 组长
var headmanid = null;
// 画师
var workersid = null;
// 查看项目是否推送
var pushleadid = null;
// 查看项目状态
var deploy = null;
// 上传稿件名称
var pic_name = null;
console.log(deploy);
console.log(pushleadid);
console.log(workersid);
console.log(leadid);
console.log(headmanid);
console.log(pic_name);
import AppBar from "../../components/AppBar";
import Editor from "../../components/Editor";

import Dialog from "./Dialog";
const typeList = [
  ".rar",
  ".zip",
  ".doc",
  ".docx",
  ".word",
  ".rtf",
  ".xls",
  ".xlsx",
  ".png",
  ".jpg",
  ".ppt",
  ".pptx",
  ".txt",
  ".pdf"
];
export default {
  data() {
    return {
      isEdit: -1,
      pms: false,
      Buttonmagic: false,
      addProject: 0,
      dialogFormVisible1: false,
      alink: "",
      dialogFormVisible: false,
      typeAll: ["标准项目", "短期项目", "插画视频项目"],
      dialogUpload: false,
      pause: true,
      url:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [],
      textarea: "",
      dialogVisible: false,
      urlName: "",
      progressName: "",
      progressName1: "",
      submission: "",
      formData: {
        pic: "",
        descr: "",
        project_id: this.$route.params.id,
        pic_name: ""
      },
      docs: "",
      activeIndex: -1,
      recordList: [],
      recordList2: [],
      feedbackList: [],
      cPurview: true,
      design_id: 0,
      cnt_feedback: 0,
      cnt: 0,
      swiperOptions: {
        // cssMode: true,
        slidesPerView: "auto",
        spaceBetween: 15,
        slideToClickedSlide: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
        // on: {
        //   click: function(e){
        //     // this.swiper.activeIndex =
        //     console.log(2342433,e,this.projectStateList)
        //     // this.$nextTick(() => {
        //     //    e.activeIndex = e.clickedIndex;
        //     // })
        //     this.clickImg(e.clickedIndex)
        //     // this.$refs.elimage[e.clickedIndex].clickHandler();

        //   }
        // }
      },
      picname: "",
      descr: "",
      projectMsg: {},
      projectStateList: ["草稿", "发布", "未通过", "通过", "推送"],
      projectStateConductList: ["未开始", "进行中", "暂停项目", "结束"],
      projectMsgOne: {},
      feedbackFlag: true,
      id: sessionStorage.getItem("id"),
      wordData: {},
      feedbackTotal: 0,
      disabled: false,
      isRestart: false, //是否重启

      releObj: {},
      projectId: 0,
      releObj: true,
      rele: true,
      projectId: 0,
      identityError: false, //身份识别
      getRole_id: sessionStorage.getItem("role_id"),
      pmupload: "0",
      design_id1: 0
    };
  },

  // components: {Dialog},
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          this.$router.go(0);
        })
        .catch(() => {});
    },
    // 判断阶段
    judgeStage() {
      if (
        this.projectMsgOne.status == 0 &&
        this.projectMsgOne.date_start == null
      ) {
        return "待开始";
      }
      if (this.projectMsgOne.is_pause == 1) {
        return "项目已暂停";
      }
      if (
        this.projectMsgOne.state == 2 &&
        this.projectMsgOne.date_start == null
      ) {
        return "项目已发布";
      }
      if (
        this.projectMsgOne.state == 3 &&
        this.projectMsgOne.date_start == null
      ) {
        return "项目已推送";
      }
      let progressList = ["草稿阶段", "色稿阶段", "细化阶段", "项目已结束"];
      return progressList[this.projectMsgOne.progress - 1];
    },
    // 修改图稿
    // imageShow(item) {
    //   console.log(item);
    //   let id = window.sessionStorage.getItem("id");
    //   let parId = parseInt(id)
    //  if(parId !== item.id){
    //     return false
    //  }else{
    //    return true
    //  }
    // },
    //取消上传图片
    cancelUpload() {
      this.dialogFormVisible1 = false;
      // this.docs = "";
      // this.$refs.upload.clearFiles();
    },

    handleRemove(file, fileList) {
      this.$refs["my-upload"].clearFiles();
      console.log(file, fileList, this.formData);
    },
    async addProjectCheck() {
      sessionStorage.setItem("addProject", 0);
      this.addProject = 0;
      this.dialogVisible = false;
      this.isRestart = false;
    },
    //重启项目
    async saveProjectCheck() {
      if (this.isRestart) {
        try {
          await this.restartProject("/api/v1.projects/startProject");
          // this.pause = !this.pause;
          setmas("项目已启动!", "success", this);
          this.addProjectCheck();
          this.$router.go(0);
        } catch (error) {
          setmas(error, "error", this);
        }
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j == "pic" || j == "pics") {
            let content = "http://ghf.site.yscase.com/" + v[j];
            return v[j] ? content : "";
          } else if (j == "level_id") {
            let levels = ["C", "B", "A", "S"];
            return levels[v[j] - 1];
          } else if (j == "status") {
            // 项目状态
            let status = ["待开始", "进行中", "有提交", "有反馈", "完成"];
            // let status = ["待开始", "进行中", "暂停", "完成"];
            return status[v[j]];
          } else if (j == "date_start_draft") {
            return v.date_start_draft + "~" + v.date_end_draft;
          } else if (j == "date_start_color") {
            return v.date_start_color + "~" + v.date_end_color;
          } else if (j == "date_start_refine") {
            return v.date_start_refine + "~" + v.date_start_refine;
          } else {
            return v[j];
          }
        })
      );
    },
    exportWord() {
      this.$common.getInterface(
        "/api/v1.designs/getFeedback",
        { project_id: this.projectMsgOne.id },
        this,
        res => {
         
          this.wordData.feedbackList = res.data;
          
          require.ensure([], () => {
            this.wordData.feedbackList.forEach(item => {
              this.wordData.list.forEach(i => {
                item.feedbackId = i.id;
                item.pic = i.pic;
              });
            });
            let params = this.wordData.feedbackList;
            console.log(params, "params");
            const {
              export_json_to_excel
            } = require("../../excel/Export2Excel");
            const tHeader = [
              "稿件地址",
              "画师姓名",
              "反馈内容",
              "反馈文件地址",
              "链接地址"
            ];
            // 上面设置Excel的表格第一行的标题
            const filterVal = ["pic", "name", "msg", "pics", "alink"];
            // 上面的filterVal中的元素是tableData里对象的属性名
            const list = params; //把data里的tableData存到list
            const data = this.formatJson(filterVal, list);
            export_json_to_excel(tHeader, data, "鬼画符项目列表");
          });
        }
      );
    },
    // 点击导出word
    // exportWord() {
    //   this.$common.getInterface(
    //     "/api/v1.designs/getFeedback",
    //     { project_id: this.projectMsgOne.id },
    //     this,
    //     res => {
    //       this.wordData.feedbackList = res.data;
    //       console.log(this.wordData);
    //       let that = this;
    //       // 判断有无附加商品来选择word模版
    //       // 读取并获得模板文件的二进制内容
    //       JSZipUtils.getBinaryContent('template.docx', function(error, content) {
    //         console.log('-----', content);
    //         // input.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
    //         // 抛出异常
    //         if (error) {
    //           throw error;
    //         }
    //         // 创建一个JSZip实例，内容为模板的内容
    //         let zip = new PizZip(content);
    //         console.log('+++++', zip);
    //         // 创建并加载docxtemplater实例对象
    //         let doc = new Docxtemplater();
    //         console.log('/////', doc);
    //         doc.loadZip(zip);
    //         console.log('=====', doc);
    //         // 设置模板变量的值
    //         doc.setData({
    //           name: that.lookDetail.name,
    //           order_date: that.lookDetail.order_time
    //         });
    //         try {
    //           // 用模板变量的值替换所有模板变量
    //           doc.render();
    //         } catch (error) {
    //           // 抛出异常
    //           let e = {
    //             message: error.message,
    //             name: error.name,
    //             stack: error.stack,
    //             properties: error.properties
    //           };
    //           console.log(JSON.stringify({ error: e }));
    //           throw error;
    //         }
    //         // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
    //         let out = doc.getZip().generate({
    //           type: 'blob',
    //           mimeType:
    //             'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    //         });
    //         // 将目标文件对象保存为目标类型的文件，并命名
    //         saveAs(out, '同意书.docx');
    //       });
    //     }
    //   );

    // },
    // 上传
    progress(response, file, fileList) {
      console.log(response);
      console.log(file.name);
      this.formData.pic_name = file.name;
      console.log(fileList);
      console.log(this.formData.pic_name);
    },
    editImage(item) {
      this.judgeUploadPermission();
      this.dialogUpload = true;
      this.formData.descr = item.descr;
      this.formData.design_id = item.id;
      this.formData.pic = item.pic;
      console.log(this.formData);
      // this.design_id1 = item.id;
      // console.log(item);
      console.log(123);
    },
    copyAlink(alinkText) {
      if (!alinkText) {
        setmas("暂无链接信息", "error", this);

        // this.$message.error("暂无链接信息");
        return false;
      }
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = alinkText; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      setmas("复制成功", "success", this);
      // this.$message.success("复制成功");
      console.log(alinkText);
    },
    upfile() {
      if (this.docs.length == 0) return false;
      this.dialogFormVisible1 = false;
    },
    errorFn(err) {
      console.log(err, "err");
      setmas("上传失败", "error", this);

      // this.$message.error("上传失败");
    },
    removeFn(file, fileList) {
      this.docs = "";
      fileList.forEach(e => {
        if (!this.docs) this.docs = e.response.data;
        else this.docs += "," + e.response.data;
      });
    },
    successFn(response, a, c) {
      if (!this.docs) this.docs = response.data;
      else this.docs += "," + response.data;
      let docList = this.docs.split(",");
      c.forEach((e, index) => {
        e.raw.url = "//ghf.site.yscase.com/" + docList[index];
        console.log(e.raw.url,'e.raw.urle.raw.url')
        e.url = this.imgsrc(e.raw);
      });
      console.log(c, "ccccc");

      setmas("上传成功", "success", this);

      // this.$message.success("上传成功");

      console.log(this.docs, "fsdjhfkshdfskjdfh");
    },
    upAlink() {
      if (this.alink.length == 0) return false;
      this.dialogFormVisible = false;
    },
    goNext() {
      this.$confirm("确认验收？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // this.$router.go(0);
          this.$emit("dialogVisible");

          this.$common.postInterface(
            "/api/v1.projects/goNext",
            { project_id: this.$route.params.id },
            this,
            res => {
              // this.$router.go(0);
              console.log("测试", this.$route.params.id);
              if (res.status == "200") {
                this.$router.go(0);
                setmas("验收成功", "success", this);

                // this.$message.success("验收成功");
              } else {
                setmas(res.msg, "error", this);

                // this.$message.error(res.msg);
              }
            }
          );
        })
        .catch(() => {
          setmas("已取消验收", "info", this);

          // this.$message({
          //   type: "info",
          //   message: "已取消验收"
          // });
        });
    },
    judgeUploadPermission() {
      // 获取当前的时间用于判断
      let nowDate = new Date(
        new Date().getFullYear() +
          "/" +
          (new Date().getMonth() + 1) +
          "/" +
          new Date().getDate()
      ).getTime();
      let workersDateFlag =
        this.projectMsgOne.id_workers_date != null &&
        new Date(
          this.projectMsgOne.id_workers_date.replace(/-/g, "/") + " 23:59:59"
        ).getTime() > nowDate;

      let JudgmentResult =
        this.projectMsgOne.id_workers &&
        this.projectMsgOne.id_workers.split(",").some(item => {
          return item == this.id;
        }) &&
        workersDateFlag;

      return JudgmentResult;
    },
    getProjectMsgOne() {
      return new Promise((resolve, reject) => {
        this.$common.getInterface(
          "/api/v1.projects/getOne",
          { id: this.$route.params.id },
          this,
          res => {
            this.userID = sessionStorage.getItem("id");
            this.roleID = sessionStorage.getItem("role_id");
            console.log(this.roleID);

            this.isEdit =
              this.roleID == 1 || res.data.id_pm.indexOf(this.userID);

            // 主美
            leadid = res.data.id_lead;
            // 组长
            headmanid = res.data.id_headman;
            // 画师
            workersid = res.data.id_workers;
            // 项目状态
            deploy = res.data.can_tiaojiao;
            console.log(deploy);
            if (res.status == "200") {
              this.projectMsgOne = res.data;
              let progressNameList = ["草稿", "色稿", "细化", "结束"];
              this.progressName = progressNameList[this.projectMsgOne.progress];
              // this.progressName1 = this.progressName;
              console.log(this.projectMsgOne.progress);
              if (this.progressName == "色稿") {
                this.progressName1 = "草稿";
              } else if (this.progressName == "细化") {
                this.progressName1 = "色稿";
              } else if (
                this.progressName == "结束" &&
                this.projectMsgOne.progress == 3
              ) {
                this.progressName1 = "细化";
              } else if (this.projectMsgOne.progress == 4) {
                this.progressName1 = "结束";
              }
              // this.projectMsgOne.progress == 1 ? "色稿" : "细化";
              this.pause = this.projectMsgOne.is_pause == 0;
              this.getWholeFeedback();
              this.feedbackFlag =
                this.judgeUploadPermission() ||
                this.projectMsgOne.id_lead == this.id ||
                this.projectMsgOne.id_pm == this.id;

              resolve();
            } else {
              setmas(res.msg, "error", this);
              reject();

              // this.$message.error(res.msg);
            }
          }
        );
      });
    },

    // pm提交按钮
    pmsubmit() {
      this.$common.postInterface(
        "/api/v1.projects/set_tijiao",
        {
          project_id: this.$route.params.id
        },
        this,
        res => {
          console.log(res);
          console.log(this.$route.params.id);
          this.$router.go(0);
        }
      );
    },
    suspend(zt) {
      // console.log(id, zt);
      console.log(zt);
      let tips2 = zt ? "确定要暂停项目吗？" : "确定要启动项目吗？";
      this.$confirm(tips2, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        //暂停项目
        if (zt) {
          try {
            await this.restartProject("/api/v1.projects/pauseProject");
            this.pause = !this.pause;
            setmas("项目已暂停!", "success", this);
            this.$router.go(0);
          } catch (error) {
            setmas(error, "error", this);
          }
        } else {
          this.$common.postInterface(
            "/api/v1.projects/startProject",
            {
              project_id: this.$route.params.id
            },
            this,
            res => {
              if (res.status === "200") {
                this.pause = !this.pause;
                setmas("项目已启动!", "success", this);
                this.$router.go(0);
              } else {
                setmas(res.msg, "error", this);
              }
            }
          );
          // this.dialogVisible = true;
          // this.isRestart = true;
        }
      });
    },
    //重启项目
    restartProject(url) {
      return new Promise((resolve, reject) => {
        this.$common.postInterface(
          url,
          {
            project_id: this.$route.params.id
          },
          this,
          res => {
            if (res.status == "200") {
              resolve("200");
              // this.$message.success(tips);
            } else {
              reject(res.msg);
              // this.$message.error(res.msg);
            }
            // console.log(res);
            // this.dataClients = res.data.data;
          }
        );
      });
    },

    postComment() {
      if (this.textarea || this.alink || this.docs) {
        this.$common.postInterface(
          "/api/v1.designs/feedback",
          {
            // pics: "/uploads/2021/03/6041ea1191d52.jpg",
            project_id: this.$route.params.id,
            msg: this.textarea,
            design_id: this.design_id,
            alink: this.alink,
            pics: this.docs
          },
          this,
          res => {
            if (res.status == "200") {
              this.getFeedback(this.design_id);
              setmas("发送反馈成功", "success", this);
              // 接口判断项目状态
              // this.$message.success("发送反馈成功");
            } else {
              setmas(res.msg, "error", this);

              // this.$message.error(res.msg);
            }
            this.textarea = "";
            this.alink = "";
            this.docs = "";
          }
        );
      }
    },
    down_fal() {
      this.dialogUpload = false;
      this.$router.go(0);
    },
    upload() {
      // if (this.formData.design_id > 0) {
      //   this.formData.design_id = this.design_id1;
      // }
      this.$common.postInterface(
        "/api/v1.designs/postDesign",
        this.formData,
        this,
        res => {
          if (res.status === "200") {
            setmas("上传稿件成功！", "success", this);

            // this.$message.success("上传稿件成功！");
            this.dialogUpload = false;
            setTimeout(() => {
              location.reload();
            }, 1000);
          } else {
            setmas(res.msg, "error", this);

            // this.$message.error(res.msg);
          }
        }
      );
      this.dialogUpload = true;
    },
    imgsrc(file) {
      let num = file.name.lastIndexOf(".");
      let type = file.name.substr(num);
      if (type === ".jpg" || type === ".png") {
        return file.url;
      } else if (type === ".rar" || type === ".zip") {
        return require("../../assets/img/yasuobao.png");
      } else if (
        type === ".doc" ||
        type === ".docx" ||
        type === ".word" ||
        type === ".rtf"
      ) {
        return require("../../assets/img/word.png");
      } else if (type === ".xls" || type === ".xlsx") {
        return require("../../assets/img/xls.png");
      } else if (type === ".ppt" || type === ".pptx") {
        return require("../../assets/img/ppt.png");
      } else if (type === ".pdf") {
        return require("../../assets/img/pdf.png");
      } else if (type === ".txt") {
        return require("../../assets/img/txt.png");
      }
    },
    success(response) {
      this.formData.pic = response.data;
    },

    isType(file) {
      let num = file.name.lastIndexOf(".");
      let type = file.name.substr(num);
      if (typeList.indexOf(type) === -1) {
        setmas("该类型的文件不能上传", "error", this);

        // this.$message.error("该类型的文件不能上传");
        return false;
      }
    },
    //回车发送
    pressEnter(event) {
      if (event.keyCode === 13) {
        this.postComment(); // 发送
        event.preventDefault(); // 阻止浏览器默认换行操作
        return false;
      }
    },
    getWholeFeedback() {
      this.$common.getInterface(
        "/api/v1.designs/getFeedback",
        { project_id: this.projectMsgOne.id },
        this,
        res => {
          console.log(res, "res");
          this.feedbackTotal = res.data.length;
        }
      );
    },
    getFeedback(id) {
      this.$common.getInterface(
        "/api/v1.designs/getFeedback",
        { design_id: id },
        this,
        res => {

          this.feedbackList = res.data.reverse();
          // console.log();
          //  this.feedbackList.forEach(res=>{
          //    res.msg = res.msg.innerHTML
          //  })
          //  console.log(this.feedbackList,'this.feedbackList')
          if (sessionStorage.getItem("role_id") == "5") {
            this.cPurview =
              res.data.length &&
              this.$store.state.personal.name == res.data[0].design_name;
          }

          // this.$router.push({ name: "Audit" });
        }
      );
    },
    getTime(date) {
      return this.$common.judgeDate(date);
    },
    viewdown(idx, name) {
      console.log(name);
      console.log(this.$refs.elimage[idx].src);
      console.log(this.picname[idx].pic_name);
      download(this.$refs.elimage[idx].src, name);
    },
    viewImg(idx) {
      // console.log(idx);
      // console.log(this.$refs.elimage[idx]);
      console.log(3244, idx);
      this.$refs.elimage[idx].clickHandler();
    },
    viewRecord(id, cnt, cnt2, descr, idx) {
      // console.log(id);
      this.design_id = id;
      this.cnt = cnt;
      this.descr = descr;
      this.cnt_feedback = cnt2;
      this.activeIndex = idx;
      // this.swiper.activeIndex = idx;
      this.$refs.elimage[idx].clickHandler();
      this.getFeedback(id);

      this.setReleIshow(); //画稿审核权限状态

      // if(status == 3 && role_id == 4) {  //已审核-组长
      //   this.releObj[role_id] = true
      // }else if(status == 5 && role_id == 3) {  //已审核-组美
      //   this.releObj[role_id] = true
      // } else{
      //   this.releObj[role_id] = false
      // }
    },
    //画稿审核权限状态
    setReleIshow() {
      if (!this.wordData.list) return;

      console.log("recordList2", this.recordList2);

      let { status, send_head } =
          (this.recordList2.length && this.recordList2[this.activeIndex]) || {},
        { role_id } = this.$store.state.personal,
        { id_lead, id_headman } = this.projectMsgOne;

      console.log("list:", this.recordList2[this.activeIndex]);
      console.log("role_id:", role_id);
      console.log("status:", status);
      console.log("activeIndex:", this.activeIndex);
      if (status !== 1) {
        this.rele = true;
      } else {
        this.rele = false;
      }
      if (id_lead == this.id || id_headman == this.id) {
        this.identityError = false;
        if (status <= 1) {
          if (role_id == 4 && send_head == 1) {
            //组长不能审核-已推送
            this.releObj = true;
          } else if (role_id == 3 && send_head == 0) {
            //主美不能审核-已推送
            this.releObj = true;
          } else {
            this.releObj = false;
          }
        } else if (status > 1 && role_id == 4) {
          //组长不能审核
          this.releObj = true;
        } else if (status == 3 && role_id == 3) {
          //主美能审核
          this.releObj = false;
        } else if (status > 3 && role_id == 3) {
          //主美不能审核
          this.releObj = true;
        }
      } else {
        this.identityError = true;
        this.releObj = true;
      }
      console.log("releObj", this.releObj);
    },
    auditDraft(id, state) {
      console.log(id, state);
      if (state == 1) {
        this.$prompt("", "请输入不通过的原因", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /\S/,
          inputErrorMessage: "原因不能为空"
        })
          .then(({ value }) => {
            this.$common.postInterface(
              "/api/v1.designs/check",
              { design_id: id, state: state, check_reason: value },
              this,
              res => {
                if (res.status == "200") {
                  this.wordData.list[this.activeIndex].status = 4;
                  this.releObj = true;
                  setmas("请求成功", "success", this);

                  // this.$message.success("请求成功");
                } else {
                  setmas(res.msg, "error", this);

                  // this.$message.error(res.msg);
                }
              }
            );
          })
          .catch(() => {});
      } else {
        this.$common.postInterface(
          "/api/v1.designs/check",
          { design_id: id, state: state },
          this,
          res => {
            if (res.status == "200") {
              this.wordData.list[this.activeIndex].status = 4;
              this.releObj = true;

              setmas("审核成功", "success", this);

              // this.$message.success("审核成功");
            } else {
              setmas(res.msg, "error", this);

              // this.$message.error(res.msg);
            }
            console.log(res);
            // this.uploadData(this.id);
            // this.$emit("dialogVisible", false);
            // setTimeout(() => {
            //   location.reload();
            // }, 1000);
            // this.dialogVisible = false;
          }
        );
      }
    },
    // 推给pm
    ToPM(id, state) {
      console.log(id);
      console.log(state);
      this.$common.postInterface(
        "/api/v1.designs/check",
        { design_id: id, state: state },
        this,
        res => {
          console.log(res);
          if (res.status == "200") {
            this.releObj = true;
            setmas(res.msg, "success", this);
            this.$router.go(0);
          } else {
            setmas(res.msg, "error", this);
          }
        }
      );
    },
    // 推给主美
    Tobeauty(id) {
      console.log(id);
      this.$common.postInterface(
        "/api/v1.designs/send_lead",
        { design_id: id },
        this,
        res => {
          console.log(res);
          if (res.status == "200") {
            this.releObj = true;
            setmas("推送成功", "success", this);
            this.$router.go(0);
          } else {
            setmas(res.msg, "error", this);
          }
        }
      );
    },
    dissjg() {
      console.log("list:", this.picname);
      // for(var i = 0;i<this.picname.length;i++){
      //  if(this.projectMsgOne.is_pause == 1||this.picname[i].status==2||this.picname[i].status==3||this.picname[i].status==4||this.picname[i].status==5){
      //    return false
      //  }else{
      //    return true
      //  }
      // }
      if (
        (this.projectMsgOne.is_pause == 1 ||
          this.picname.length == 0 ||
          this.picname[this.picname.length - 1].status == 2 ||
          this.picname[this.picname.length - 1].status == 3 ||
          this.picname[this.picname.length - 1].status == 4 ||
          this.picname[this.picname.length - 1].status == 5) &&
        this.projectMsgOne.is_pause != 1
      ) {
        return false;
      } else {
        return true;
      }
    },
    // 提交

    pmdisbled() {
      if (deploy == 1 && (this.submission == 3 || this.submission == 5)) {
        return false;
      } else {
        return true;
      }

      // if(this.submission=="3"||this.submission=="5"){
      //    return false
      // }else{

      //   return true
      // }
    }
  },
  async created() {
    await this.getProjectMsgOne();
    this.urlName = sessionStorage.getItem("urlName");
    this.$common.getInterface(
      "/api/v1.designs/getList",
      { project_id: this.$route.params.id },
      this,
      res => {
        this.recordList = res.data;
        console.log(this.recordList);
        this.picname = res.data.list;

        this.submission =
          (res.data.list.length &&
            res.data.list[res.data.list.length - 1].status) ||
          0;
        // console.log(res.data.[数组.length-1]);
        for (let item of res.data.list) {
          // 项目图片判断
          // this.recordList2.push(item);
          // 画师显示的稿件
          // if (
          //   window.sessionStorage.getItem("role_id") <= 4 ||
          //   item.status == "5" ||
          //   item.status === "3" ||
          //   item.admin_id == window.sessionStorage.getItem("id")
          // ) {
          //   this.recordList2.push(item);
          //   this.srcList.push("//ghf.site.yscase.com/" + item.pic);
          // }
          // ---------------
          pushleadid = item.send_head;
          // if(item.state=="1"){
          //   this.rele = true
          // }else{
          //    this.rele = false
          // }
          // console.log(item.status);
          if (item.status == "3" || item.status == "5") {
            //逻辑代码。。。
            this.recordList2.push(item);
            this.srcList.push("//ghf.site.yscase.com/" + item.pic);
          } else {
            switch (window.sessionStorage.getItem("role_id")) {
              case "4":
                if (
                  headmanid &&
                  window.sessionStorage.getItem("id") == headmanid
                ) {
                  //逻辑代码。。。
                  this.recordList2.push(item);
                  this.srcList.push("//ghf.site.yscase.com/" + item.pic);
                }
                break;
              case "3":
                if (
                  leadid &&
                  window.sessionStorage.getItem("id") == leadid &&
                  item.send_head == "1"
                ) {
                  //逻辑代码。。。
                  this.recordList2.push(item);
                  this.srcList.push("//ghf.site.yscase.com/" + item.pic);
                }
                break;
              case "5":
                if (
                  workersid &&
                  workersid.indexOf(window.sessionStorage.getItem("id")) !== -1
                ) {
                  //逻辑代码。。。
                  this.recordList2.push(item);
                  this.srcList.push("//ghf.site.yscase.com/" + item.pic);
                }
                break;
            }
          }
          // ---------------
          // if (sessionStorage.getItem("role_id") != "2") {
          //   if (item.status * 1 < 5) this.recordList2.push(item);
          // } else {
          //   if (item.status * 1 >= 5) this.recordList2.push(item);
          // }
          // this.recordList2.push(item);
          // this.srcList.push("//ghf.site.yscase.com/" + item.pic);
        }
        switch (window.sessionStorage.getItem("role_id")) {
          case "3":
            this.Buttonmagic = true;
            // if (pushleadid == 1) {
            //   this.Buttonmagic = true;
            //   console.log("显示");
            // } else if(pushleadid != 1) {
            //   this.Buttonmagic = false;
            //   console.log("隐藏");
            // }
            break;
          case "1":
            this.Buttonmagic = false;
            console.log("隐藏");
            break;
          case "2":
            this.Buttonmagic = false;
            console.log("隐藏");
            break;
          case "5":
            this.Buttonmagic = false;
            console.log("隐藏");
            break;
          case "4":
            this.Buttonmagic = true;
            break;
        }
        this.wordData.list = res.data.list;
        this.projectMsg = res.data.project;
        this.activeIndex = this.recordList2.length - 1;

        console.log("项目信息：", this.projectMsg);

        this.addProject = sessionStorage.getItem("addProject");
        this.projectId = this.$route.params.id;

        // let {need_check_lead,need_check_pm,need_check_headman} =  this.projectMsgOne,
        // {role_id} = this.$store.state.personal

        // if(need_check_lead)

        // this.projectMsgOne.need_check_lead || (this.wordData.list && this.wordData.list[this.wordData.list.length-1].status !=1)) && $store.state.personal.role_id != 3

        this.setReleIshow(); //画稿审核权限状态

        if (this.recordList2.length) {
          this.design_id = this.recordList2[this.activeIndex].id;
          this.descr = this.recordList2[this.activeIndex].descr;
          this.getFeedback(this.recordList2[this.activeIndex].id);
          setTimeout(() => {
            this.$refs.mySwiper.$swiper.slideTo(this.activeIndex, 1000, false);
          }, 500);
        }
      }
    );
  },

  computed: {
    token() {
      return { token: window.sessionStorage.getItem("token") };
    },
    check() {
      return window.sessionStorage.getItem("role_id") != "3";
    },
    swiper() {
      return this.$refs.mySwiper;
    }
  },
  components: {
    AppBar,
    Dialog,
    Editor
    // Dialog: resolve => {
    //   require(["./Dialog"], resolve);
    // } //懒加载
  }
};
// 提醒弹出框

function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup"
  });
}
/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {Promise}
 */
function getBlob(url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();

    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };

    xhr.send();
  });
}

/**
 * 保存
 * @param  {Blob} blob
 * @param  {String} filename 想要保存的文件名称
 */
function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    const body = document.querySelector("body");

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = "none";
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
}

/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
function download(url, filename) {
  getBlob(url).then(blob => {
    saveAs(blob, filename);
  });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;
  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>